/* --- GENERAL --- */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {

    /* COLORS VARIABLES */
    --white-color: #FFF;
    --dark-color: #0B0F0F;
    --grey-color: #6B7280;
    --grey-option-color:#868589;
    --light-grey-color: #E1E1E1;
    --light-grey-option-color: #F4F4F4;
    --light-grey-third-color: #CBCBCB;
    --primary-color: #EC5323;
    --primary-color-alpha: rgba(255, 95, 45, 0.8);
    --secondary-color: #A988F5;
    --tertiary-color: #FDF8F3;
    --above-color: #065F46;
    --above-color-bg: #D1FAE5;
    --average-color: #92400E;
    --average-color-bg: #FEF3C7;
    --below-color: #991B1B;
    --below-color-bg: #FEE2E2;


    /* FONTS VARIABLES */
    --font: 'Poppins';
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;

    /* OTHER VARIABLES */
    --max-content-width: 1400px;
    --normal-content-width: 1200px;
    --default-transition-duration: 0.4s;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: sans-serif;
    font-family: var(--font);
    font-weight: var(--font-weight-regular);
    color: var(--dark-color);
    line-height:1.4em;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

h1, h2, h3, h4, h5, h6 {
    font-size: 26px;
    font-weight: var(--font-weight-semi-bold);
    margin-top: 40px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    color: var(--grey-color);
    margin: 10px 0 20px;
}

p span {
    color: var(--primary-color);
}

p a {
    font-weight: var(--font-weight-semi-bold);
}

p a:hover {
    color: var(--secondary-color);
}

strong {
    font-weight: var(--font-weight-semi-bold);
}

input[type="text"],
textarea,
input[type="password"],
input[type="email"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    font-family: sans-serif;
    font-family: var(--font);
}

button {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-family: sans-serif;
    font-family: var(--font);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.button {
    background-color: var(--dark-color);
    color: var(--white-color);
    font-weight: var(--font-weight-medium);
    display: inline-block;
    align-items: center;
    padding: 14px 60px;
    border-radius: 5px;
    transition-duration: var(--default-transition-duration);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:hover {
    opacity:0.6;
    background-color: var(--grey-option-color);
}

.primary-color {
    color: var(--primary-color);
}

.bg-color-1 {
    background-color: var(--primary-color);
}

.button.bg-color-1:hover {
    opacity: 0.6 !important;
    background-color: var(--primary-color) !important;
    border:none !important;
    color: var(--white-color) !important;
}

.bg-color-2 {
    background-color: var(--secondary-color);
}

.button.bg-color-2:hover {
    opacity: 0.6 !important;
    background-color: var(--secondary-color) !important;
    border:none !important;
    color: var(--white-color) !important;
}

.bg-color-3 {
    background-color: var(--tertiary-color);
}

.below-color {
    color: var(--below-color);
}

.average-color {
    color: var(--average-color);
}

.above-color {
    color: var(--above-color);
}

.triangle::after {
    content: "";
    display: block;
    position: relative;
    top: -3px;
    margin-left: 10px;
    transition-duration: var(--default-transition-duration);
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--dark-color);
    font-weight: var(--font-weight-regular);
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: var(--white-color);
    border:1px solid var(--primary-color);
    border-radius: 3px;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: var(--primary-color);
}

.color-1 {
    color: var(--primary-color);
}

.color-2 {
    color: var(--secondary-color);
}

.color-3 {
    color: var(--tertiary-color);
}

/* --- scrollbar --- */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--light-grey-third-color);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--grey-option-color);
}

.after-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.after-title a {
    font-weight: var(--font-weight-medium);
    font-size: 14px;
}

/* --- Loading Component --- */

.loading-component {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.loading-component.loading-absolute {
    position: absolute;
    height: 100%;
}

.loading-component-animation {
    width: 60px;
    height: 60px;
    display: inline-block;
    position: relative;
}

.loading-component-animation::before,
.loading-component-animation::after {
    content: '';  
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0);
    animation: animloader 1s linear infinite;
    animation-delay: 0s;
}

.loading-component-animation::after {
    animation-delay: 0.5s;
}

.loading-component.button-loading {
    background-color: transparent;
    z-index: auto;
    width: 100%;
    height: 90px;
    position: relative;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* --- Error Component --- */

.error-component {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    z-index: 9998;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.error-component .forms h1 {
    margin-top: 10px;
}

.error-component .forms button {
    max-width: 260px;
}

/* --- Header Component --- */

.header-component {
    padding: 10px 0;
    border-bottom: 1px solid var(--light-grey-third-color);
}

.header-component-container {
    max-width: var(--max-content-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-component-left {
    display: flex;
    align-items: center;
}

.header-component-right {
    display: flex;
    align-items: center;
}

.header-component .button {
    padding: 10px 20px;
}

@media all and (max-width: 450px) {
    .header-component .button {
        font-size: 14px;
    }
}

/* --- Logo Component --- */

.logo-component {
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: var(--default-transition-duration);
    padding: 10px 40px;
    -webkit-tap-highlight-color: transparent;
}

.logo-component img {
    width: 137px;
}

.logo-component:hover {
    transform: scale(1.05);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media all and (max-width: 600px) {
    .logo-component {
        padding: 10px 20px;
    }
}

@media all and (max-width: 450px) {
    .logo-component {
        padding-left: 20px;
        padding-right: 10px;
    }
    .logo-component img {
        width: 105px;
    }
}

/* --- Menu component --- */

.menu-component-items {
    display: flex;
    align-items: center;
}

.menu-component .main-menu {
    display: flex;
    align-items: center;
}

.menu-component a {
    display: block;
    color: var(--dark-color);
    margin-right: 40px;
    font-weight: var(--font-weight-regular);
    font-size: 18px;
}

.menu-component a.active {
    font-weight: var(--font-weight-semi-bold);
}

.menu-component a:hover {
    color:var(--primary-color);
}

.menu-component .button {
    color: var(--white-color);
    margin-right: 0;
}

.menu-component .button:hover {
    color: var(--white-color);
}

.menu-component-opener {
    display: none;
}

@media all and (max-width: 900px) {
    .menu-component a {
        font-size: 16px;
    }
}

@media all and (max-width: 850px) {
    .menu-component-items {
        display: none;
    }
    .menu-component-items.opened {
        width: 90%;
        left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        background-color: var(--white-color);
        padding: 20px 20px 10px 20px;
        -webkit-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
        -moz-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
        box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
        z-index: 100;
    }
    .menu-component-opener {
        display: block;
        position: relative;
        width: 50px;
        height: 46px;
        padding: 15px 10px;
    }
    .menu-component-opener span {
        display: block;
        position: relative;
        height: 100%;
        border-bottom: 1px solid var(--dark-color);
        border-top: 1px solid var(--dark-color);
    }
    .menu-component a {
        margin-right: 0;
        white-space: nowrap;
        margin-bottom: 10px;
    }
    .menu-component .button {
        background-color: transparent;
        padding: 0;
        color: var(--dark-color);
    }
    .menu-component .button:hover {
        color: var(--primary-color);
        opacity: 1;
    }
    .menu-component-opener span::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--dark-color);
        top: 50%;
    }
    .menu-component .main-menu {
        flex-direction: column;
        align-items: flex-end;
    }
}

@media all and (max-width: 600px) {
    .menu-component-opener {
        padding: 15px 5px;
        width: 42px;
        height: 46px;
    }
}

/* --- Login User Buttons Components --- */

.login-user-btn-component {
    position: relative;
    cursor: pointer;
    margin: 0 40px 0 0;
}

.user-btn-logout {
    margin-left: -10px;
}

.user-btn-logout button {
    font-weight: var(--font-weight-medium);
}

.user-btn-logout button:hover {
    color:var(--primary-color);
}

@media all and (max-width: 600px) {
    .login-user-btn-component {
        margin: 0 20px 0 -10px;
    }
    .user-btn-logout {
        margin-left: 0;
    }
}

@media all and (max-width: 400px) {
    .login-user-btn-component {
        margin: 0 10px 0 -10px;
    }
    .user-btn-logout {
        margin-left: -5px;
    }
}

/* --- Language Selector Component --- */

.lang-sel-component {
    position: relative;
    margin: 0 20px;
    padding: 5px 20px;
}

.lang-sel-component-others {
    display: none;
    position: absolute;
    top: 100%;
    right: 20px;
    background-color: var(--white-color);
    border-radius: 5px;
    padding: 10px 10px;
    -webkit-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    text-align: center;
    z-index: 100;
}

.lang-sel-component-others button {
    display: inline-block;
    color: var(--dark-color);
    padding: 5px 20px;
    cursor: pointer;
}

.lang-sel-component-others button.selected {
    color: var(--primary-color);
}

.lang-sel-component-others button:hover {
    color: var(--primary-color);
}

.lang-sel-component-selected {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    margin-top: 1px;
}

.lang-sel-component-selected.triangle::after {
    border-width: 0 2px 2px 0;
    margin-left: 7px;
}

.lang-sel-component:hover .triangle::after {
    transform: rotate(225deg) translateY(-4px) translateX(-2px);
}

.lang-sel-component:hover .lang-sel-component-others {
    display: block;
}

@media all and (max-width: 600px) {
    .lang-sel-component {
        margin: 0 10px 0 0;
        padding-left: 0;
    }
}

/* --- forms --- */
      
.forms {
    width: 96%;
    max-width: 450px;
    margin: 20px auto;
    text-align: center;
}

.forms form {
    border: 1px solid var(--light-grey-color);
    border-radius: 8px;
    padding: 30px 35px 15px;
    margin: 30px 0 20px;
}

.forms input[type="text"],
.forms input[type="email"],
.forms input[type="password"] {
    border: 1px solid var(--light-grey-color);
    display: block;
    width: 100%;
    border-radius: 5px;
    margin: 20px 0 25px;
    padding: 10px 15px;
    color: var(--dark-color);
}

.forms input[type="text"]::placeholder,
.forms input[type="email"]::placeholder,
.forms input[type="password"]::placeholder {
    color: var(--grey-option-color);
}

.forms input[type="text"]:focus,
.forms input[type="email"]:focus,
.forms input[type="password"]:focus {
    outline: none !important;
    border: 1px solid var(--secondary-color);
}

.forms input[type="checkbox"] {
    accent-color: var(--primary-color);
}

.forms .forms-check-label {
    display: flex;
    align-items: flex-start;
    margin: 25px 0 10px;
}

.forms .forms-check-label input {
    margin: 5px 10px 0 0;
}

.forms .forms-check-label span {
    text-align: left;
    font-size: 14px;
}

.forms .forms-check-label span a {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
}

.forms .button {
    width: 100%;
    margin: 20px 0;
    font-size: 18px;
    font-weight: var(--font-weight-regular);
}

.forms .button:hover {
    border: 1px solid var(--dark-color);
    background-color: var(--white-color);
    color: var(--dark-color);
    opacity:1;
}

.forms-error {
    color: var(--dark-color);
    font-weight: var(--font-weight-medium);
}

.errors {
    animation: shake 0.3s ease;
}

.forms-link {
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
    margin-top: 20px;
    margin-bottom: 3px;
}

.forms-link a {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    15% { transform: rotate(2deg); }
    30% { transform: rotate(0eg); }
    45% { transform: rotate(-2deg); }
    60% { transform: rotate(0deg); }
    75% { transform: rotate(2deg); }
    90% { transform: rotate(0eg); }
}

/* --- Products Component --- */

.product-component {
    position: relative;
    margin: 0 auto;
    width: 90%;
    max-width: var(--normal-content-width);
}

.product-component header {
    padding: 45px 0 25px;
    border-bottom: 1px solid var(--light-grey-color);
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.product-breadcrumbs {
    font-size: 14px;
    color: var(--grey-option-color);
}

.product-breadcrumbs a {
    font-size: 14px;
    color: var(--dark-color);
    font-weight: var(--font-weight-medium);
}

.product-breadcrumbs .product-breadcrumb-separator {
    margin: 0 2px;
    font-weight: var(--font-weight-medium);
    color: var(--grey-option-color);
}

.product-breadcrumbs .product-breadcrumbs-segment {
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
}

.product-component header h1 {
    margin-top: 7px;
    font-size: 20px;
}

.product-nav-buttons {
    text-align: right;
    min-width: 40%;
}

.product-nav-buttons button {
    height: 44px;
    line-height: 44px;
    padding: 0 30px;
    margin-left: 10px;
}

.product-container {
    position: relative;
    overflow: hidden;
    padding: 10px 0;
}

.product-container h1,
.product-container h2,
.product-container h3,
.product-container h4,
.product-container h5,
.product-container h6 {
    font-weight: var(--font-weight-semi-bold);
    margin: 10px 0 25px;
    max-width: 700px;
}

.product-container p {
    margin: 10px 0 20px;
    max-width: 950px;
}

@media all and (max-width: 600px) {
    .product-component header {
        flex-direction: column;
        padding: 40px 0 25px;
    }
    .product-header-titles {
        text-align: center;
    }
    .product-nav-buttons {
        min-width: auto;
    }
    .product-nav-buttons button {
        margin-right: 5px;
        margin-left: 5px;
        margin: 20px 5px 10px;
    }
}

/* --- Progress Bar Component --- */

.component-progress-bar {
    float: right;
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-left: 30px;
}

.progress-bar-container {
    width: 125px;
    height: 10px;
    background-color: var(--light-grey-color);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-bar-color {
    position: relative;
    background-color: var(--primary-color);
    height: 100%;
    width: 0%;
    border-radius: 5px;
    transition-duration: 2s;
}

.progress-bar-text {
    font-size: 14px;
    font-weight: var(--font-weight-light);
    color: var(--grey-option-color);
    padding-left: 20px;
    display: flex;
    word-spacing: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.component-progress-bar.big-progress {
    float: none;
    margin-left: 0;
}

.big-progress .progress-bar-container {
    width: 400px;
}

@media all and (max-width: 800px) {
    .component-progress-bar {
        position: relative;
        justify-content: left;
        padding: 0;
        float: none;
        margin: 20px 0;
    }
    .progress-bar-text {
        padding-left: 15px;
    }
    .progress-bar-text span {
        width: 10px;
    }
    .big-progress .progress-bar-container {
        width: 200px;
    }
}

@media all and (max-width: 500px) {
    .big-progress .progress-bar-container {
        width: 140px;
    }
    .component-progress-bar.big-progress {
        margin: 15px 0;
    }
}

/* --- Two Column Product Design --- */

.two-column {
    display: flex;
    gap: 40px;
    align-items: flex-start;
    position: relative;
    margin-top: 10px;
}

.two-column .column:first-child {
    width: 45%;
}

.two-column .column:last-child {
    width: 55%;
    background-color: #FBF9F7;
    padding: 20px 30px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
}

@media all and (max-width: 800px) {
    .two-column {
        flex-direction: column;
        gap: 10px;
    }
    .two-column .column:first-child {
        width: 100%;
    }
    .two-column .column:last-child {
        width: 100%;
    }
}

/* --- Description Rating Component --- */

.description-rating-component .question-number {
    margin: 20px 0;
    color: var(--grey-color);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: var(--font-weight-regular);
}

.question-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
    border-radius: 10px;
    margin: 10px 0 30px;
}

.question-rating .button {
    padding: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2em;
    font-weight: var(--font-weight-regular);
    width: 110px;
    height: 65px;
    background-color: var(--white-color);
    color: var(--dark-color);
    border: 1px solid var(--light-grey-third-color);
}

.question-rating .button:hover {
    background-color: var(--primary-color);
    opacity: 1;
    color: var(--white-color);
}

.description-rating-component .question {
    margin-top: 20px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.description-rating-component .options-title {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    margin: 30px 0 0;
    color: var(--dark-color);
}

.softScale {
    animation: softScale 0.3s;
}

.softSlide {
    animation: softSlide 0.3s;
}

@keyframes softSlide {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes softScale {
    0% {
        transform: scale(0.6);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media all and (max-width: 1050px) {
    .description-rating-component .component-progress-bar {
        position: relative;
        justify-content: left;
        padding: 0;
        float: none;
        margin: 20px 0 25px;
    }
    .question-rating .button:hover {
        background-color: var(--white-color);
        color: var(--dark-color);
    }
    .question-rating .button:active {
        background-color: var(--primary-color);
        opacity: 1;
        color: var(--white-color);
    }
}

/* --- Need Help Component --- */

.need-help-component {
    position: relative;
}

h1 + .need-help-component,
h2 + .need-help-component
h3 + .need-help-component
h4 + .need-help-component
h5 + .need-help-component
h6 + .need-help-component {
    margin-top: -25px;
    position: relative;
    margin-bottom: 15px;
}

.need-help-opener {
    cursor: pointer;
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}  

.need-help-opener img {
    margin-top:-2px;
}

.need-help-dialog {
    background-color: var(--white-color);
    display: none;
    position: absolute;
    padding: 30px 25px;
    top: 100%;
    left: 10px;
    max-width: 80%;
    -webkit-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    font-weight: var(--font-weight-medium);
}

.need-help-opener:hover + .need-help-dialog {
    display: block;
}

/* --- Final Component --- */

.final-component {
    text-align: center;
    padding: 100px 0;
    max-width: 500px;
    margin: auto;
}

.final-component h1 {
    margin-bottom: 30px;
    font-size: 30px;
}

.final-component .see-results {
    margin-top: 10px;
    background-color: var(--white-color);
    color: var(--dark-color);
    border: 1px solid var(--dark-color);
    font-weight: var(--font-weight-semi-bold);
}

@media all and (max-width: 800px) {
    .final-component {
        padding: 30px 0;
    }
}

/* --- Learning Paths --- */

.learning-paths-component {
    width:90%;
    max-width: var(--normal-content-width);
    margin: 0 auto;
    position: relative;
}

.learning-paths-component header {
    border-bottom: 1px solid var(--light-grey-third-color);
    padding: 45px 0 10px;
}

.learning-paths-selector {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    padding-right: 40px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.learning-paths-selector h1 {
    font-size: 32px;
    font-weight: var(--font-weight-medium);
    margin: 0;
}

.learning-paths-selector h1 strong {
    font-size: 32px;
    font-weight: var(--font-weight-medium);
}

.learning-paths-selector .triangle::after {
    top: 50%;
    margin-left: 0;
    width: 4px;
    height: 4px;
    position: absolute;
    right: 5px;
    margin-top: -13px;
    border-color: var(--primary-color);
    border-width: 0 4px 4px 0;
}

.learning-paths-selector:hover .triangle::after {
    transform: rotate(225deg) translate(-5px, -3px);
    -webkit-transform: rotate(225deg) translate(-5px, -3px);
}

.learning-paths-select {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--white-color);
    -webkit-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    box-shadow: -2px 0px 8px 2px rgba(0,0,0,0.1);
    z-index: 10;
    padding: 0 10px;
}

.learning-paths-selector:hover .learning-paths-select {
    display: flex;
    flex-direction: column;
}

.learning-paths-select a {
    display: block;
    padding: 15px 20px;
    text-align: center;
    border-bottom: 1px solid var(--light-grey-color);
    font-size: 18px;
    color: var(--grey-color);
}

.learning-paths-select a:hover {
    color: var(--primary-color) !important;
}

.learning-paths-select a.active {
   color: var(--dark-color);
   font-weight: var(--font-weight-medium);
}

.learning-paths-select a:last-of-type {
    border-bottom: none;
}

.learning-paths-path {
    display: flex;
    overflow: auto;
    padding: 0 0 30px;
}

.learning-path-segment {
    max-width: 280px;
    min-width: 240px;
}

.learning-path-segment h5 {
    text-align: left;
    margin: 0;
    padding: 30px 15px 20px;
    overflow: hidden;
    font-weight: var(--font-weight-regular);
    color: var(--grey-color);
    border-right: 1px solid var(--light-grey-third-color);
}

.learning-path-segment:last-of-type h5 {
    border-right: none;
}

.learning-path-segment:has(.completed) h5,
.learning-path-segment:has(.available) h5 {
    font-weight: var(--font-weight-semi-bold);
    color: var(--dark-color);
}

.learning-path-segment-content {
    border-right: 1px solid var(--light-grey-third-color);
    height: calc(100% - 94px);
}

.learning-path-segment:last-of-type .learning-path-segment-content {
    border-right: none;
}

.path-header-component {
    position: relative;
}

.path-header-component h2 {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    margin-top: 0;
    margin-bottom: 10px;
}

.path-header-back {
    position: absolute;
    bottom: 20px;
    right: 0;
    padding: 10px 20px;
}

.path-header-component .component-progress-bar {
    padding-top: 10px;
}

@media all and (max-width: 800px) {
    .learning-paths-selector {
        padding-bottom: 0;
    }
    .learning-paths-selector .triangle:after {
        margin-top: -10px;
    }
    .learning-paths-selector h1,
    .learning-paths-selector h1 strong {
        font-size: 26px;
    }
}

@media all and (max-width: 700px) {
    .path-header-back {
        position: relative;
        bottom: 0;
        margin-bottom: 20px;
    }
}

@media all and (max-width: 600px) {
    .learning-paths-selector h1,
    .learning-paths-selector h1 strong {
        font-size: 22px;
    }
    .learning-paths-component header {
        padding: 40px 0 5px;
    }
}

/* --- results component --- */

.results-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.results-component-title {
    font-size: 24px;
    font-weight: var(--font-weight-semi-bold);
    margin: 0;
}

.results-component-title span {
    font-size: 24px;
    font-weight: var(--font-weight-semi-bold);
}

.total-score-value {
    margin: 0 0 0 10px;
}

.total-score-fb {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
}

.score-fb-tag {
    background-color: #DBEAFE;
    color: #1E40AF;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    text-align: center;
    display: inline-block;
    white-space: nowrap;
}

.score-fb-tag.below {
    color: var(--below-color);
    background-color: var(--below-color-bg);
}

.score-fb-tag.average {
    color: var(--average-color);
    background-color: var(--average-color-bg);
}

.score-fb-tag.above {
    color: var(--above-color);
    background-color: var(--above-color-bg);
}

.total-score-fb img {
    display: block;
    width: 20px;
    height: 20px;
}

.total-score-info {
    display: none;
    background-color: var(--white-color);
    padding: 20px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 240px;
    -webkit-box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    z-index: 2;
}

.total-score-fb:hover .total-score-info {
    display: block;
}

.total-score-info h6 {
    margin: 0;
}

.total-score-info p {
    margin: 10px 0;
    font-size: 14px;
}

.total-score-info p span {
    width: 110px;
    display: inline-block;
    margin-right: 10px;
}

.results-container {
    margin-top: 30px;
}

.result-table-header {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.result-table-header h3,
.result-table-header h4 {
    margin: 0;
    font-size: 20px;
    font-weight: var(--font-weight-medium);
    color: #404040;
}

.result-table-header .score-fb-tag {
    align-self: center;
}

.result-table {
    overflow: auto;
    margin-bottom: 50px;
    padding: 5px;
}

.result-table table {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    border-collapse: collapse;
    -webkit-box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05);
    border: 1px solid #E5E7EB;
    border-radius: 10px;
    overflow:hidden;
}

.result-table table th {
    font-size: 12px;
    color: var(--grey-option-color);
    padding: 12px 20px;
    text-transform: uppercase;
}

.result-table table thead tr {
    background-color: #F9FAFB;
    border-bottom: 1px solid #E5E7EB;
}

.result-table table tbody tr {
    background-color: var(--white-color);
    border-bottom: 1px solid #E5E7EB;
}

.prod-third-col {
    display: flex;
    align-items: center;
    gap: 25px;
    min-width: 400px;
}

.prod-third-col p {
    margin: 0;
    font-size: 14px;
}

.result-table table tbody tr td {
    padding: 10px 20px;
    font-size: 14px;
}

.result-table table tbody tr td:first-of-type {
    text-align: left;
    font-weight: var(--font-weight-medium);
}

.score-single-product-fb.hidden {
    display: none;
}

.score-single-product-fb td {
    font-size: 13px !important;
    font-weight: var(--font-weight-regular) !important;
    color: var(--grey-color);
    padding-left: 20px !important;
    padding-bottom: 15px !important;
}

.result-table table tbody tr.selected {
    border-bottom: none;
}

.result-arrow {
    border: solid var(--dark-color);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 6px;
    position: relative;
    top: -1px;
    transition-duration: 0.5s;
}

.result-arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: -3px;
}

@media all and (max-width: 1200px) {
    .result-table-header h3,
    .result-table-header h4 {
        font-size: 18px;
    }
}

@media all and (max-width: 850px) {
    .results-container {
        flex-direction: column;
    }
    .result-table {
        width: 100%;
    }
}

@media all and (max-width: 750px) {
    .results-header {
        flex-direction: column;
    }
    .results-component-title {
        text-align: center;
        font-size: 28px;
    }
    .results-component-title span {
        font-size: 28px;
    }
    .total-score-info {
        right: -50px;
    }
    .result-table table tbody tr td {
        padding: 6px;
    }
    .total-score-fb {
        padding: 0 0 10px;
    }
}

@media all and (max-width: 450px) {
    .result-table {
        padding: 20px 10px;
    }
    .result-table-header h3,
    .result-table-header h4 {
        font-size: 17px;
    }
    .prod-second-col {
        width: 60px;
        min-width: 60px;
    }
}

/* --- product link component --- */

.product-link-component {
    display: block;
    background-color: var(--white-color);
    border-radius: 10px;
    border: 1px solid var(--light-grey-color);
    padding: 15px;
    margin: 0 15px 15px;
    -webkit-box-shadow: -2px 0px 4px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: -2px 0px 4px 2px rgba(0,0,0,0.05);
    box-shadow: -2px 0px 4px 2px rgba(0,0,0,0.05);
}

.product-link-component:hover {
    border: 1px solid var(--primary-color);
}

.product-link-component svg {
    transition: var(--default-transition-duration);
}

.product-link-component:hover svg {
    transform: scale(1.2) rotate(360deg);
}

.product-link-component h6 {
    margin: 0;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
}

.product-link-component.unavailable {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: var(--light-grey-option-color);
    opacity: 0.4;
    cursor: auto;
    pointer-events: none;
}

.product-link-start,
.product-link-completed {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.product-link-start span,
.product-link-completed span {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
}

.product-link-icon {
    margin-left: 6px;
}

.product-link-start .product-link-icon {
    position: relative;
    top: -1px;
}

.product-link-component.completed .product-link-start {
    display: none;
}

.product-link-component.available .product-link-completed {
    display: none;
}

.product-link-component.unavailable .product-link-completed {
    display: none;
}

.product-link-component.unavailable .product-link-icon svg path {
    stroke: var(--dark-color);
}

/* --- Insights Component --- */

.insight-question {
    margin-bottom: 0 !important;
}

.insight-description {
    line-height: 1.5em;
}

.insights-component textarea {
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    background-color: var(--light-grey-option-color);
    border: none;
    outline: 0;
    padding: 30px;
    font-style: italic;
    border-radius: 8px;
}

.insights-component .button {
    padding: 12px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.insights-component .button img {
    margin-right: 10px;
    transition-duration: var(--default-transition-duration);
}

.insights-component .button:hover img {
    transform: scale(1.2);
}

.insights-quantity {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.insights-quantity .separator {
    display: inline-block;
    width: 2px;
    height: 35px;
    background-color: var(--dark-color);
    margin: -12px 10px 0 12px;
}

.no-insights {
    font-size: 14px;
    color: var(--dark-color);
    font-weight: var(--font-weight-medium);
    margin-top: 0 !important;
}

.insight-component {
    background-color: var(--white-color);
    border-radius: 12px;
    padding: 25px;
    margin: 10px 0;
    position: relative;
}

.insight-component p {
    font-size: 16px;
    margin: 0 20px 0 0;
}

.insight-component button {
    position: absolute;
    top: 20px;
    right: 15px;
    transition-duration: var(--default-transition-duration);
    width: 30px;
    height: 30px;
    padding: 9px;
}

.insight-component button img {
    display: block;
    width: 100%;
    height: 100%;
}

.insight-component button:hover {
    transform: scale(1.4);
}

/* --- pagination --- */

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.pagination button {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    width: 40px;
    padding: 15px 0;
    text-align: center;
    display: block;
    border-top: solid 2px transparent;
    transition-duration: var(--default-transition-duration);
}

.pagination button:hover:not(.selected) {
    color: var(--primary-color);
}

.pagination button.selected {
    border-top: solid 2px var(--primary-color);
}

/* --- multiple choice component --- */

.multiple-choice-component .custom-checkbox {
    padding: 15px 10px 15px 40px;
    border: 1px solid var(--light-grey-third-color);
    max-width: 940px;
    border-radius: 8px;
    margin: 10px 0;
}

.multiple-choice-component .custom-checkbox:hover {
    background-color: var(--light-grey-option-color);
}

.multiple-choice-component .checkmark {
    top: 19px;
    left: 13px;
}

.multiple-choice-component .checkmark::after {
    content:"";
    display: block;
    width:8px;
    height:3px;
    border-bottom:2px solid var(--white-color);
    border-left:2px solid var(--white-color);
    position:relative;
    left: 2px;
    top: 3px;
    transform: rotate(-45deg);
}

.multiple-choice-component .options-container {
    margin: 10px 0 50px;
}

.multiple-choice-component h5 {
    margin: 40px 0 10px;
}

.long-checkbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.long-checkbox .custom-checkbox {
    width: calc(33% - 20px);
    max-width: calc(33%);
    margin: 7px 20px 7px 0;
}

@media all and (max-width: 650px) {
    .long-checkbox .custom-checkbox {
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-right: 10px;
        font-size: 14px;
    }
}

@media all and (max-width: 450px) {
    .long-checkbox .custom-checkbox {
        width: 100%;
        margin-right: 0;
    }
}

/* --- single choice component --- */

.single-choice-component h5,
.single-choice-component h1 {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.single-choice-component h5 {
    margin: 30px 0 25px;
}

.single-choice-answers {
    margin-top: 10px;
}

.single-choice-answers .question-rating {
    margin-top: 0;
    justify-content: flex-start;
}

.single-choice-component .options-title {
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    margin: 25px 0 0;
    color: var(--dark-color);
}

@media all and (max-width: 900px) {
    .single-choice-answers .question-rating {
        justify-content: center;
    }
}

/* --- Write Down component --- */

.single-choice-component textarea,
.idea-question-component textarea {
    margin-top: 30px;
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 300px;
    background-color: var(--light-grey-option-color);
    border: none;
    outline: 0;
    padding: 30px 20px;
    border-radius: 10px;
    font-style: italic;
    color: var(--grey-color);
}

/* --- Ordered List Component --- */

.ordered-list-component li {
    margin-left: 20px;
    margin-bottom: 2px;
    padding-left: 10px;
    color: var(--grey-color);
}

/* --- Next Button component --- */

.next-button-component {
    margin: 40px 0;
}

.next-button-component .button {
    padding: 12px 40px;
    border-radius: 8px;
}